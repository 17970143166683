import { FC } from "react";

type Props = {
    fill?: string,
    width?: number,
    height?: number,
    style?: string,
}

const Changev2: FC<Props> = ({ fill, width, height, style, ...props }) => (
    <svg
        className={style}
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill={"none"}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_7343_61232)">
        <path d="M23.4964 13.3467C23.2294 13.3467 22.9624 13.2668 22.7421 13.1136L18.1295 9.94354C17.5221 9.52397 17.3685 8.69815 17.7891 8.09211C18.2096 7.48606 19.0373 7.33289 19.6448 7.75245L22.6286 9.80368L23.3629 6.55368C23.5231 5.83442 24.244 5.38821 24.9583 5.54805C25.6792 5.70789 26.1264 6.42049 25.9662 7.13975L24.7914 12.3078C24.6913 12.7407 24.3909 13.0936 23.977 13.2535C23.8168 13.3134 23.6566 13.3467 23.4897 13.3467H23.4964Z" fill={fill}/>
        <path d="M12.6833 1.99798C17.8366 1.99798 22.0286 6.18036 22.0286 11.3218H23.3636C23.3636 5.43446 18.5842 0.666016 12.6833 0.666016C6.78239 0.666016 2.00293 5.43446 2.00293 11.3218H3.33797C3.33797 6.18036 7.53002 1.99798 12.6833 1.99798Z" fill={fill}/>
        <path d="M24.0307 11.9877H21.3606V11.3217C21.3606 6.54662 17.469 2.66393 12.6828 2.66393C7.89671 2.66393 4.00505 6.54662 4.00505 11.3217V11.9877H1.33496V11.3217C1.33496 5.08145 6.42816 0 12.6828 0C18.9375 0 24.0307 5.08145 24.0307 11.3217V11.9877Z" fill={fill}/>
        <path d="M2.51014 12.6533C2.77715 12.6533 3.04416 12.7332 3.26444 12.8864L7.87703 16.0565C8.48447 16.4761 8.638 17.3019 8.21746 17.9079C7.79692 18.514 6.96919 18.6672 6.36175 18.2476L3.37792 16.1964L2.64365 19.4464C2.48344 20.1656 1.76252 20.6118 1.04827 20.452C0.327346 20.2922 -0.119894 19.5795 0.0403117 18.8603L1.21515 13.6923C1.31528 13.2594 1.61566 12.9064 2.02953 12.7466C2.18973 12.6866 2.34994 12.6533 2.51682 12.6533H2.51014Z" fill={fill}/>
        <path d="M13.3171 24.0025C8.16381 24.0025 3.97176 19.8201 3.97176 14.6787H2.63672C2.63672 20.566 7.41618 25.3344 13.3171 25.3344C19.218 25.3344 23.9974 20.566 23.9974 14.6787H22.6624C22.6624 19.8201 18.4704 24.0025 13.3171 24.0025Z" fill={fill}/>
        <path d="M1.96875 14.0127H4.63884V14.6787C4.63884 19.4538 8.5305 23.3365 13.3166 23.3365C18.1028 23.3365 21.9944 19.4538 21.9944 14.6787V14.0127H24.6645V14.6787C24.6645 20.9189 19.5713 26.0004 13.3166 26.0004C7.06195 26.0004 1.96875 20.9256 1.96875 14.6787V14.0127Z" fill={fill}/>
        </g>
        <defs>
        <clipPath id="clip0_7343_61232">
        <rect width="26" height="26" fill="white"/>
        </clipPath>
        </defs>
    </svg>
);

export default Changev2;
