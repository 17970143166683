
import Image from 'next/image';
import React, { useState, useEffect, FC } from 'react';
import { Interbank } from '@framework/types/home';
import s from './Converter.module.css';
import { ConverterData } from '@framework/data/converter';
import { HouseOnly } from '@framework/types/exchangehouses';
import Changev2 from '../icons/changev2';
import Equals from '../icons/Equals';
import ArrowNormal from '../icons/ArrowNormal';
import { convertTime } from '@framework/lib/dateFormat';
import ConverterSkeleton from '../Skeleton/ConverterSkeleton';

interface ConverterProps {
	exchangeDolar: Interbank;
	houseOnly: HouseOnly | undefined;
	valueCalcular: (
		result: number,
		currencySent: string,
		estado: string
	) => void;
    currentConverter?: ConverterData | undefined;
    page?: string;
}

const ConverterDinamic: FC<ConverterProps> = ({
	exchangeDolar,
	houseOnly,
	valueCalcular,
    currentConverter,
    page
}) => {

	const [currencyReceived, setCurrencyReceived] = useState('USD');
	const [currencySent, setCurrencySent] = useState('PEN');
	const [convertir, setConvertir] = useState('convertir');
	const [valueSoles, setValueSoles] = useState<number | null>(null);
	const [houseChange, setHouseChange] = useState<number | null>(null);
	const [valueDolares, setValueDolares] = useState<number | null>(null);
	const [change2, setChange2] = useState(false);
	// const [value, setValue] = useState<number | null>(null);
    const [value, setValue] = useState<string>(''); // Almacenar como cadena
    const [convertedValue, setConvertedValue] = useState<number | null>(null);
    const [houseConvertedValue, setHouseConvertedValue] = useState<number | null>(null);

    const [activeTab, setActiveTab] = useState('Compra'); // Estado para controlar la pestaña activa
    const [activeFocus, setActiveFocus] = useState <boolean>(false); 

    // Estado para controlar el focus de la pestaña activa


    const handleTabClick = (tab: string) => {
        change()
        setActiveTab(tab);
    };

	// const formattedValue = value !== null ? (value as number).toLocaleString('en-US', {
	// 	minimumFractionDigits: 2,
	// 	maximumFractionDigits: 2,
	// }) : '';

	// const formattedValueUSD = valueDolares !== null ? (valueDolares as number).toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }) : '';
      
	// const formattedValueHouse = houseChange !== null ? (houseChange as number).toLocaleString('en-US', {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   }) : '';
      

	const currencyIcons = {
		USD: {
			icon: '/icons/flagUSA.svg',
			text: 'USD',
		},
		PEN: {
			icon: '/icons/flagPeru.svg',
			text: 'PEN',
		},
	};

	type CurrencyType = keyof typeof currencyIcons;

	// useEffect(() => {
	// 	if(houseOnly?.rates){
	// 		const { buy, sale } = houseOnly?.rates;
	// 		const valorSoles: number | null = value;
	// 		if (valorSoles && valorSoles !== 0) {
	// 			if (currencySent == 'PEN') {
	// 				const result =
	// 					Number(valorSoles) / Number(exchangeDolar.sale.cost);
	// 				const resultHouse = Number(valorSoles) / Number(sale?.cost);
	// 				setValueDolares(
	// 					Number(
	// 						result.toFixed(2).toLocaleString().replace(/[^\d]/g, '')
	// 					) / 100
	// 				);
	// 				setHouseChange(
	// 					Number(
	// 						resultHouse
	// 							.toFixed(2)
	// 							.toLocaleString()
	// 							.replace(/[^\d]/g, '')
	// 					) / 100
	// 				);
	// 			} else if (currencySent == 'USD') {
	// 				const result =
	// 					Number(valorSoles) * Number(exchangeDolar.buy.cost);
	// 				const resultHouse = Number(valorSoles) * Number(buy?.cost);
	// 				setValueDolares(
	// 					Number(
	// 						result.toFixed(2).toLocaleString().replace(/[^\d]/g, '')
	// 					) / 100
	// 				);
	// 				setHouseChange(
	// 					Number(
	// 						resultHouse
	// 							.toFixed(2)
	// 							.toLocaleString()
	// 							.replace(/[^\d]/g, '')
	// 					) / 100
	// 				);
	// 			}
	// 			valueCalcular(valorSoles, currencySent, 'convertir');
	// 		} else {
	// 			setConvertir('convertir');
	// 			setValueSoles(0);
	// 			setValueDolares(0);
	// 			valueCalcular(0, '', 'regresar');
	// 		}
	// 	}
	// }, [valueCalcular, value]);

      // Manejar el cambio en el campo de entrada
    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

      // Realizar cálculos cuando cambie el valor de entrada o la moneda enviada
  useEffect(() => {
    if (houseOnly?.rates) {
      const { buy, sale } = houseOnly.rates;
      const numericValue = parseFloat(value.replace(/,/g, ''));

      if (!isNaN(numericValue)) {
        if (currencySent === 'PEN') {
          const result = numericValue / Number(exchangeDolar.sale.cost);
          const resultHouse = numericValue / Number(sale?.cost);
          setConvertedValue(result);
          setHouseConvertedValue(resultHouse);
        } else if (currencySent === 'USD') {
          const result = numericValue * Number(exchangeDolar.buy.cost);
          const resultHouse = numericValue * Number(buy?.cost);
          setConvertedValue(result);
          setHouseConvertedValue(resultHouse);
        }
        valueCalcular(numericValue, currencySent, 'convertir');
      } else {
        setConvertedValue(null);
        setHouseConvertedValue(null);
        valueCalcular(0, '', 'regresar');
      }
    }
  }, [value, currencySent, exchangeDolar, houseOnly, valueCalcular]);
    
    // useEffect(() => {
    //     if(houseOnly?.rates){
    //        const { buy, sale } = houseOnly?.rates;
    //        const valorSoles: number | null = value;
    //        if (valorSoles && valorSoles !== 0) {
    //           if (currencySent === 'PEN') {
    //              const result = Number(valorSoles) / Number(exchangeDolar.sale.cost);
    //              const resultHouse = Number(valorSoles) / Number(sale?.cost);
    //              setValueDolares(Number(result.toFixed(2).replace(/[^\d]/g, '')) / 100);
    //              setHouseChange(Number(resultHouse.toFixed(2).replace(/[^\d]/g, '')) / 100);
    //           } else if (currencySent === 'USD') {
    //              const result = Number(valorSoles) * Number(exchangeDolar.buy.cost);
    //              const resultHouse = Number(valorSoles) * Number(buy?.cost);
    //              setValueDolares(Number(result.toFixed(2).replace(/[^\d]/g, '')) / 100);
    //              setHouseChange(Number(resultHouse.toFixed(2).replace(/[^\d]/g, '')) / 100);
    //           }
    //           valueCalcular(valorSoles, currencySent, 'convertir');
    //        } else {
    //           setConvertir('convertir');
    //           setValueSoles(0);
    //           setValueDolares(0);
    //           valueCalcular(0, '', 'regresar');
    //        }
    //     }
    //  }, [houseOnly, exchangeDolar, valueCalcular, value, currencySent]);
     

	// const inputHandlerSoles = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	console.log('event.target.value', event.target.value);
    //     const inputElement = event.target;

    //     // Guardar la posición actual del cursor
    //     const start = inputElement.selectionStart;
    //     const end = inputElement.selectionEnd;
    //     const newValue = Number(event.target.value.replace(/[^\d]/g, '')) / 100;
	// 	setValue(newValue);
    //     // Esto asegura que el componente se renderice primero, y luego restauramos el cursor
    //     setTimeout(() => {
    //         // Restaurar la posición del cursor
    //         if (inputElement.setSelectionRange) {
    //             inputElement.setSelectionRange(start, end);
    //         }
    //     }, 0);
	// 	setChange2(!change2);
	// };

    // const inputHandlerSoles = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputElement = event.target;
    //     const inputValue = inputElement.value;
    
    //     // Guardar la posición del cursor antes de modificar el valor
    //     const start = inputElement.selectionStart;
    //     const end = inputElement.selectionEnd;
    
    //     // Detectar si el usuario está eliminando caracteres
    //     const isDeleting = event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType === 'deleteContentBackward';
    
    //     // Reemplazar todos los caracteres no numéricos
    //     let formattedValue = inputValue.replace(/[^\d]/g, '');
    
    //     // Manejar el caso cuando el input está vacío
    //     if (formattedValue === '') {
    //         setValue(null); // Establecer el valor como null cuando se borra todo
    //     } else {
    //         // Convertir el valor a número, dividiendo por 100 solo si hay un valor numérico
    //         const newValue = Number(formattedValue) / 100;
    
    //         // Actualizar el estado con el valor numérico
    //         setValue(newValue);
    //     }
    
    //     // Solo restaurar la posición del cursor si el usuario está borrando un número
    //     if (isDeleting) {
    //         setTimeout(() => {
    //             if (inputElement.setSelectionRange) {
    //                 inputElement.setSelectionRange(start, end);
    //             }
    //         }, 0);
    //     }
    
    //     // Actualizar el estado relacionado con 'change2'
    //     setChange2(!change2);
    // };

    const handleFocus = () => {
        setActiveFocus(true);
    };

    const handleBlur = () => {
        setActiveFocus(false);
    };

	const inputHandlerDolares = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const enteredName =
			Number(event.target.value.replace(/[^\d]/g, '')) / 100;
		setValueDolares(
			Number(
				enteredName.toFixed(2).toLocaleString().replace(/[^\d]/g, '')
			) / 100
		);
		if (currencySent == 'PEN') {
			const result = Number(valueSoles) / Number(exchangeDolar.sale.cost);
			setValueDolares(
				Number(
					result.toFixed(2).toLocaleString().replace(/[^\d]/g, '')
				) / 100
			);
		} else if (currencySent == 'USD') {
			const result = Number(valueSoles) * Number(exchangeDolar.buy.cost);
			setValueDolares(
				Number(
					result.toFixed(2).toLocaleString().replace(/[^\d]/g, '')
				) / 100
			);
		}
		valueCalcular(Number(valueSoles), currencySent, 'convertir');
	};

	const currencyIcon = (sent: boolean, txt: boolean = true) => {
		const currency = sent ? currencySent : currencyReceived;
		const { icon, text } = currencyIcons[currency as CurrencyType];
		return (
			<div className='p-1 focus:outline-none focus:shadow-outline flex mr-2'>
				<Image
					src={icon}
					width={35}
					height={31}
					className='icon_calcular'
					alt='icon mobile'
				/>
				{txt && (
					<span className='text-black font-bold text-2xl my-auto uppercase'>
						{text}
					</span>
				)}
			</div>
		);
	};

	// const change = () => {
    //     setValue(null);
    //     setHouseChange(null);

	// 	setCurrencySent(currencyReceived);
	// 	setCurrencyReceived(currencySent);
	// 	setChange2(!change2);
	// };
    const change = () => {
        setValue('');
        setConvertedValue(null);
        setHouseConvertedValue(null);
        setCurrencySent(currencyReceived);
        setCurrencyReceived(currencySent);
      };
      // Formatear los valores para mostrar
  const formattedValue = value;
  const formattedConvertedValue =
    convertedValue !== null
      ? convertedValue.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '';

  const formattedHouseConvertedValue =
    houseConvertedValue !== null
      ? houseConvertedValue.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '';

    const styleHeadbg = {
        background: currentConverter?.color,
    };

    const styleTextPrincipal = {
        color: currentConverter?.text,
    };

    const styleTextSecondary = {
        color: currentConverter?.text2,
    };
    
    const valor = (signo: string , variacion: number) =>
        signo === 'equals' ? (
        <div className='flex justify-center items-center'>
            <Equals width={8} height={5} fill={'#4D4B4B'} />
            <p className={`pl-[6px] text-xs text-[#4D4B4B]`}>0.000</p>
        </div>
        ) : signo === 'negative' ? (
        <div className='flex justify-center items-center'>
            <div className='rotate-180'>
            <ArrowNormal width={8} height={5} fill={'#80387D'} />
            </div>
            <p className={`text-third2 pl-[6px] text-xs`}>{variacion ? variacion : 0}</p>
        </div>
        ) : (
        <div className='flex justify-center items-center'>
            <ArrowNormal width={8} height={5} fill={'#02A702'} />
            <p className={`text-[#02A702] pl-[6px] text-xs`}>{variacion ? variacion : 0}</p>
        </div>
        );

        console.log('currentConverter', currentConverter);
	return (
		<>
            {currentConverter ? (
                <div className='relative'>
                    {/* <div className='text-2xs text-[#8e8e8e] -mb-4 md:-mb-0'>Auspicia</div> */}
                    <div className='flex justify-between '>
                        <div style={styleHeadbg} className={`block px-2 rounded-t-lg md:-bottom-1.5 relative h-[45px] md:h-[55px] w-[180px] shadow-top`}>
                            <a
                                href={currentConverter.site}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <div className='pt-1 pl-1 md:pt-2 md:pl-2'>
                                    <Image
                                        src={`${currentConverter.logo}`}
                                        width={100}
                                        height={35}
                                        alt='img logo'
                                    />
                                </div>
                            </a>
                        </div>
                        {houseOnly && (   
                            <div className='md:pt-0 w-full pl-2 block'>  
                                <div className='flex justify-end mr-2 items-start '>
                                    <div className= 'md:mx-7 mx-3 text-start mt-0 pt-0 flex items-start'>
                                        <button className={`font-medium text-xs m-0 p-0  ${activeTab === 'Venta' ? 'text-[#8E8E8E]' : '' }`} onClick={() => handleTabClick('Compra')}>
                                            Compra: {parseFloat(houseOnly.rates?.buy?.cost ?? '').toFixed(3)}
                                        </button>
                                    </div>
                                    <div className='md:mx-3 text-start flex items-start'>
                                        <button className={`font-medium text-xs m-0 p-0 ${activeTab === 'Compra' ? 'text-[#8E8E8E]' : '' }`} onClick={() => handleTabClick('Venta')}>
                                            Venta: {parseFloat(houseOnly.rates?.sale?.cost ?? '').toFixed(3)}
                                        </button>
                                    </div>
                                </div>  
                                <div className='border-b-[bg-#E6E0E9] border-b flex justify-end mr-2 items-start md:pt-2 pt-1'>
                                    <div className= 'md:mx-7 mx-3 text-start mt-0 pt-0 w-[79px]'>
                                    <span className={`${activeTab === 'Compra' ? s.barra_violeta : s.barra_white }`}></span>
                                    </div>
                                    <div className='md:mx-3 text-start w-[67px] bg-white'>
                                    <span className={`${activeTab === 'Venta' ? s.barra_violeta : s.barra_white }`}></span>
                                    </div>
                                </div>  
                                <div className={`flex items-center ${houseOnly.timestamp ? 'justify-around' : 'justify-end'} md:pt-1.5`}>
                                    {houseOnly && houseOnly.timestamp && (
                                        <div className='flex items-center'>
                                            <div className='flex items-center'>
                                                <Image
                                                    src='/icons/iconUpdate.svg'
                                                    width={9}
                                                    height={10}
                                                    alt='icon refresh'
                                                />
                                                <p className='text-2xs text-black mx-2 '>
                                                    {convertTime(houseOnly.timestamp)}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                        <div className={`${houseOnly.timestamp ? 'mx-auto' : 'mr-10'} md:mx-4 font-medium text-base leading-5 text-white w-[55px]`}>
                                            {activeTab === 'Compra' && (
                                                <span>{valor(houseOnly.rates?.buy?.signal ?? '', houseOnly.rates?.buy?.variation ?? 0)}</span>
                                            )}
                                        </div>
                                        <div className={`${houseOnly.timestamp ? 'md:mx-4 mr-5' : 'mr-6 md:mr-8'} font-medium text-base leading-5 w-[55px]`}>
                                            {activeTab === 'Venta' && (
                                                <span>{valor(houseOnly.rates?.sale?.signal ?? '', houseOnly.rates?.sale?.variation ?? 0)}</span>
                                            )}
                                        </div>
                                </div>  
                            </div>
                        )}
                    </div>
                    <div style={styleHeadbg} className={`rounded-tl-none rounded-lg relative filter drop-shadow-sm md:px-9 px-4 md:pb-6 pb-[15px]`}>
                        <div className='mx-auto md:w-full mb-6 pt-4'>
                            <p style={styleTextPrincipal}
                                className={` uppercase font-semibold text-xs pb-2 md:pt-4`}>
                                tu envías{' '}
                                {currencySent === 'PEN' ? 'SOLES' : 'DÓLARES'}
                            </p>
                            <div className='relative divide-x divide-lgray'>
                                <div className='block'>
                                    <span
                                        className={` ${
                                            convertir == 'convertir'
                                                ? 'absolute py-1.5 px-3 rounded-md text-2xl'
                                                : 'absolute  text-[#ffe11b] px-3 py-1 rounded-md text-2xl'
                                        } `}>
                                        {currencySent === 'PEN' ? 'S/' : '$'}{' '}
                                    </span>
                                    {/* <input
                                        type='text'
                                        maxLength={12}
                                        pattern='[0-9]*'
                                        value={formattedValue}
                                        onFocus={handleFocus} 
                                        onBlur={handleBlur}
                                        onChange={inputHandlerSoles}
                                        aria-label='Valor a convertir con el tipo de cambio'
                                        placeholder='0.00'
                                        className={`${
                                            convertir == 'convertir'
                                                ? 'bg-white  '
                                                : 'bg-[#0000006c] text-[#ffe11b] disabled:opacity-100'
                                        } w-full py-1.5 pl-10 pr-2 rounded-md text-2xl`}
                                        disabled={convertir !== 'convertir'}
                                    /> */}
                                        <input
                                            type='text'
                                            value={formattedValue}
                                            onChange={inputHandler}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            placeholder='0.00'
                                            className='bg-white w-full py-1.5 pl-10 pr-2 rounded-md text-2xl'
                                        />
                                    <span className={`absolute inset-y-0 right-0 flex items-center pl-2 ${s.lineCustom}`}>
                                        {currencyIcon(true)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <span style={styleTextPrincipal} className='uppercase font-semibold text-xs absolute top-[100px] md:top-[130px] mr-9'>
                            Tú recibes{' '}
                            {currencySent === 'PEN' ? 'DÓLARES' : 'SOLES'}
                        </span> */}
                        
                        <div className='left-[45%] absolute -mt-4 md:-mt-5'>
                        <button
                                className='hidden bg-white rounded-full w-[30px] h-[30px] md:w-10 md:h-10 md:flex align-middle justify-center  p-1.5 cursor-pointer hover:shadow-lg rotate-90'
                                onClick={change}
                                disabled={convertir !== 'convertir'}
                                >
                                    <Changev2
                                    width={26}
                                    height={26}
                                    fill={`${activeFocus ? '#000' : '#8E8E8E'}`}
                                    >
                                    </Changev2>
                            </button>
                            <button
                                className='md:hidden bg-white rounded-full w-[30px] h-[30px] md:w-10 md:h-10 flex align-middle justify-center  p-1.5 cursor-pointer hover:shadow-lg rotate-90'
                                onClick={change}
                                disabled={convertir !== 'convertir'}
                                >
                                    <Changev2
                                    width={19}
                                    height={19}
                                    fill={`${activeFocus ? '#000' : '#8E8E8E'}`}
                                    >
                                    </Changev2>
                            </button>
                        </div>
                        <div className='mx-auto md:w-full'>
                            <div className='flex justify-between mb-2'>
                                <span style={styleTextPrincipal} className='uppercase font-semibold text-xs'>
                                    tu Recibes{' '}
                                    {currencySent === 'PEN' ? 'DÓLARES' : 'SOLES'}
                                </span>
                                <span style={styleTextSecondary} className='text-2xs md:text-xs '>
                                    *Valor {currentConverter.title}
                                </span>
                            </div>
                        </div>
                        <div className='mx-auto md:w-full'>
                            <div className='relative mx-auto'>
                                <span className={`${activeFocus ? 'text-black' : 'text-[#8E8E8E]' } absolute py-1.5 px-3 rounded-md text-2xl`}>
                                    {currencyReceived === 'USD' ? '$' : 'S/'}
                                </span>
                                {/* <input
                                    type='text'
                                    maxLength={12}
                                    value={formattedValueHouse}
                                    onChange={inputHandlerSoles}
                                    onFocus={handleFocus} 
                                    onBlur={handleBlur}
                                    aria-label='Conversión del valor con el tipo de cambio de la casa de cambio'
                                    className='bg-white w-full py-1.5 pl-10 pr-2 rounded-md text-2xl'
                                    placeholder='0.00'
                                    
                                /> */}
                                    <input
                                        type='text'
                                        value={formattedHouseConvertedValue}
                                        readOnly
                                        className='bg-white w-full py-1.5 pl-10 pr-2 rounded-md text-2xl'
                                        placeholder='0.00'
                                    />
                                <span
                                    className={`absolute inset-y-0 right-0 flex items-center pl-2 ${s.lineCustom}`}>
                                    {currencyIcon(false)}
                                </span>
                            </div>
                        </div>
                        <button className={`${activeFocus ? 'text-black shadow-lg' : 'text-[#DADADA]' } flex justify-center mt-[15px] md:mt-6 h-[36px] w-[140px] mx-auto items-center bg-white rounded-full font-medium hover:shadow-lg text-xs hover:text-black active:text-black `}>
                            <a
                                href={page == 'cambioOnline' ? currentConverter.site + '-conline' : currentConverter.site }
                                target='_blank'
                                rel='noreferrer nofollow'
                                >
                                CAMBIAR
                            </a>
                        </button>
                    </div>
                </div>
            ) : (
                <ConverterSkeleton />
            )}
		</>
	);
};

export default ConverterDinamic;
